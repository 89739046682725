import * as React from "react"
import { MDXProvider } from "@mdx-js/react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { ExternalLink } from './ExternalLink'

const __PATH_PREFIX__ = ""

const components = {
	a: ExternalLink,
}

export const Layout = ({ location, title, children }) => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          company {
            name
            address
            number
            disclaimer
          }
        }
      }
    }
  `)

  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
        <Link to="/">
          <StaticImage
          className="header-logo"
          layout="fixed"
          formats={["auto", "webp", "avif"]}
          src="../images/blog-header-logo.svg"
          width={142}
          height={142}
          quality={95}
          alt="barns.dev logo for header"
        />
        </Link>
    )
  } else {
    header = (
      <Link to="/">
          <StaticImage
          className="header-logo"
          layout="fixed"
          formats={["auto", "webp", "avif"]}
          src="../images/blog-header-logo.svg"
          width={96}
          height={96}
          quality={95}
          alt="barns.dev logo for header"
        />
        </Link>
    )
  }

  const company = data.site.siteMetadata?.company

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <>
		    <MDXProvider components={components}>{children}</MDXProvider>
	    </>
      <footer>
        <p>
          This site is the blog of { company.name } of { company.address } with company number { company.number }. { company.disclaimer }
        </p>
        <p>
        © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.com">Gatsby</a>
        </p>
      </footer>
    </div>
  )
}
